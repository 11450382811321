/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {LoginCreative} from './components/LoginCreative'
import {useAuth} from './core/Auth'
import {AUTH_LOCAL_STORAGE_KEY} from './core/AuthHelpers'
import {getUserByTokenV2} from './core/_requests'

const AuthLayoutV2 = () => {
  const {auth, logout, setCurrentUser, saveAuth} = useAuth()
  const location = useLocation()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (token: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByTokenV2(token)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (location) {
      if (location.search) {
        const token = location.search?.replaceAll('?token=', '')
        if (token?.length > 0) {
          saveAuth({
            status: true,
            message: 'authorized',
            data: {
              accessToken: token,
              refreshToken: token,
            },
          })
          console.log('zxczxccvb', localStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
          requestUser(token)
          // setTimeout(() => {
          //   getUserByToken(token).then((res) => {
          //     console.log(res.data)
          //     setCurrentUser(res.data)
          //     setShowSplashScreen(false)
          //   })
          // }, 2000)
        }
      }
    }
  }, [location])

  useEffect(() => {
    console.log('zxczxczxc', auth)
  }, [auth])

  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
      className='d-flex flex-column flex-root'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg4.jpg')})`,
      }}
    >
      {/*begin::Page bg image*/}
      {/* <style dangerouslySetInnerHTML={{__html: `body { background-image: url(${toAbsoluteUrl('/media/auth/bg4.jpg')}); } [data-theme="dark"] body { background-image: url('assets/media/auth/bg4-dark.jpg'); }` }} /> */}
      {/*end::Page bg image*/}
      {/*begin::Authentication - Sign-in */}
      <div className='d-flex flex-column flex-column-fluid flex-lg-row'>
        {/*begin::Aside*/}
        <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
          {/*begin::Aside*/}
          <div className='d-flex flex-center flex-lg-start flex-column'>
            {/*begin::Logo*/}
            <a href='../../demo2/dist/index.html' className='mb-7'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-universa-light.png')} />
            </a>
            {/*end::Logo*/}
            {/*begin::Title*/}
            {/* <h2 className="text-white fw-normal m-0">Branding tools designed for your business</h2> */}
            {/*end::Title*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*begin::Aside*/}
        {/*begin::Body*/}
        <div className='d-flex flex-center w-lg-50 p-10'>
          {/*begin::Card*/}
          <div className='card rounded-3 w-md-550px'>
            {/*begin::Card body*/}
            <div className='card-body p-10 p-lg-20'>
              {/*begin::Form*/}
              <Outlet />
              {/*end::Form*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Authentication - Sign-in*/}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayoutV2 />}>
      <Route path='login' element={<LoginCreative />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<LoginCreative />} />
    </Route>
  </Routes>
)

export {AuthPage}
