import {create} from 'zustand'

export interface AppUniversa {
  title: string
  desc: string
  icon: string
  url: string
  internalUrl: string
}

interface GlobalState {
  apps: AppUniversa[]
  hrApps: AppUniversa[]
  activeApps: AppUniversa
  setActiveApps: (value: AppUniversa) => void
}

export const useGlobalStore = create<GlobalState>((set, get) => ({
  apps: [
    {
      title: 'Donasi & Affiliate',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/programs',
    },
    {
      title: 'CRM',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/crm',
    },
    {
      title: 'Finance',
      desc: '',
      icon: '/media/portals/finance.png',
      url: process.env.REACT_APP_FINANCE_PLATFORM,
      internalUrl: '/apps/finance',
    },
    {
      title: 'Program & Distribusi',
      desc: '',
      icon: '/media/portals/program.png',
      url: process.env.REACT_APP_PROGRAM_PLATFORM,
      internalUrl: '/apps/programs',
    },
    {
      title: 'Marbot',
      desc: '',
      icon: '/media/portals/unibot.png',
      url: process.env.REACT_APP_UNIBOT_PLATFORM,
      internalUrl: '/apps/unibot',
    },
    {
      title: 'Wallet',
      desc: '',
      icon: '/media/portals/wallet.png',
      url: process.env.REACT_APP_WALLET_PLATFORM,
      internalUrl: '/apps/wallet',
    },
    {
      title: 'Marketplace',
      desc: '',
      icon: '/media/portals/marketplace.png',
      url: process.env.REACT_APP_MERCHANDISE_PLATFORM,
      internalUrl: '/apps/marketplace',
    },
    // {
    //   title: 'BI',
    //   desc: '',
    //   icon: '/media/portals/marketplace.png',
    //   url: process.env.REACT_APP_BI_PLATFORM,
    //   internalUrl: '/apps/bi',
    // },
  ],
  hrApps: [
    {
      title: 'Cuti',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Izin',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Tugas Luar',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Lembur',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Absensi',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Rekam Kegiatan',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Rekap Absensi',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
    {
      title: 'Slip Gaji',
      desc: '',
      icon: '/media/portals/crm.png',
      url: process.env.REACT_APP_CRM_PLATFORM,
      internalUrl: '/apps/hr',
    },
  ],
  activeApps: null,
  setActiveApps: (value: AppUniversa) => {
    set({activeApps: value})
  },
}))
