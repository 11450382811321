import {useIntl} from 'react-intl'
import {AUTH_LOCAL_STORAGE_KEY, AuthModel, useAuth} from '../../../auth'
import {generateImageUrl} from '../../../../../_metronic/helpers/generate-image-url'
import {useState} from 'react'
import {useGlobalStore} from '../../globals/states/global.state'
import {useNavigate} from 'react-router-dom'
import {KTCard} from '../../../../../_metronic/helpers'

const DashboardScreen = () => {
  const {apps, activeApps, setActiveApps, hrApps} = useGlobalStore()
  const navigate = useNavigate()

  return (
    <>
      <div className='mb-10'>
        <h1 style={{textAlign: 'center'}}>Apps</h1>
      </div>
      <div
        className='row g-3 g-xl-3 gap-5'
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {apps.map((item, index) => (
          <div
            className='card cursor-pointer w-300px col-sm-2'
            key={index}
            onClick={(e) => {
              e.preventDefault()
              navigate(item.internalUrl)
            }}
          >
            {/*begin::Body*/}
            <div className='card-body text-center'>
              {/*begin::Food img*/}
              <img
                src={item.icon}
                className='rounded-3 mb-4 w-70px h-70px'
                style={{
                  objectFit: 'contain',
                }}
                alt='image_apps'
              />
              {/*end::Food img*/}
              {/*begin::Info*/}
              <div className='mb-2'>
                {/*begin::Title*/}
                <div className='text-center'>
                  <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                    {item.title}
                  </span>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Info*/}
              {/*begin::Total*/}
              {/*end::Total*/}
            </div>
            {/*end::Body*/}
          </div>
        ))}
      </div>
      <KTCard className='mt-5'>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-item-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>HR</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Human Resource</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div
            className='row g-3 g-xl-3 gap-5'
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hrApps.map((item, index) => (
              <div
                className='card cursor-pointer w-300px col-sm-2 border'
                key={index}
                onClick={(e) => {
                  e.preventDefault()
                  navigate(item.internalUrl)
                }}
              >
                {/*begin::Body*/}
                <div className='card-body text-center'>
                  {/*begin::Food img*/}
                  <img
                    src={item.icon}
                    className='rounded-3 mb-4 w-70px h-70px'
                    style={{
                      objectFit: 'contain',
                    }}
                    alt='image_apps'
                  />
                  {/*end::Food img*/}
                  {/*begin::Info*/}
                  <div className='mb-2'>
                    {/*begin::Title*/}
                    <div className='text-center'>
                      <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1'>
                        {item.title}
                      </span>
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Total*/}
                  {/*end::Total*/}
                </div>
                {/*end::Body*/}
              </div>
            ))}
          </div>
        </div>
      </KTCard>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.APPS'})}</PageTitle> */}
      <DashboardScreen />
    </>
  )
}

export default DashboardWrapper
