import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosInterceptorSetup, AxiosProgramInterceptorSetup, AxioxCrmInterceptorSetup } from "../../api/api";
import { useAuth } from "./core/Auth";

const AxiosInterceptorNavigate = () => {
  const navigate = useNavigate();
  const {logout} = useAuth();
  useEffect(() => {
    AxiosInterceptorSetup(navigate, logout);
    AxioxCrmInterceptorSetup(navigate, logout);
    AxiosProgramInterceptorSetup(navigate, logout);
  }, []);
  return <></>;
}

export default AxiosInterceptorNavigate;