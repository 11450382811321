import {Outlet, Route, Routes} from 'react-router-dom'
import {useGlobalStore} from '../globals/states/global.state'
import {useEffect} from 'react'
import {AUTH_LOCAL_STORAGE_KEY, AuthModel} from '../../auth'

const AppsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='crm'
          element={
            <>
              <CRMWrapper />
            </>
          }
        />
        <Route
          path='finance'
          element={
            <>
              <FinanceWrapper />
            </>
          }
        />
        <Route
          path='unibot'
          element={
            <>
              <UnibotWrapper />
            </>
          }
        />
        <Route
          path='programs'
          element={
            <>
              <ProgramWrapper />
            </>
          }
        />
        <Route
          path='wallet'
          element={
            <>
              <WalletWrapper />
            </>
          }
        />
        <Route
          path='marketplace'
          element={
            <>
              <MarketplaceWrapper />
            </>
          }
        />
        <Route
          path='bi'
          element={
            <>
              <BiWrapper />
            </>
          }
        />
        <Route
          path='hr'
          element={
            <>
              <HRWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

const HRWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'HR',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_BI_PLATFORM}`,
      internalUrl: '/apps/hr',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'HR Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const CRMWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'CRM',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_CRM_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/crm',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'CRM Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const FinanceWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'Finance',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_FINANCE_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/finance',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Finance Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const UnibotWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'Unibot',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_UNIBOT_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/unibot',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Wallet Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const ProgramWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'CRM',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_PROGRAM_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/programs',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Program Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const WalletWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'Wallet',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_WALLET_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/wallet',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Wallet Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const MarketplaceWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'Marketplace',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_MERCHANDISE_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/marketplace',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Marketplace Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

const BiWrapper = () => {
  const {activeApps, setActiveApps} = useGlobalStore()

  useEffect(() => {
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    setActiveApps({
      title: 'Business Intelligence',
      desc: '',
      icon: '',
      url: `${process.env.REACT_APP_BI_PLATFORM}?token=${auth?.data?.accessToken}`,
      internalUrl: '/apps/bi',
    })
  }, [])

  return (
    <>
      {activeApps !== null ? (
        <div
          style={{
            marginTop: -40,
            marginLeft: -30,
            marginRight: -30,
            marginBottom: -40,
            padding: '0px',
            overflow: 'hidden',
            zIndex: -999,
          }}
        >
          <iframe
            src={`${activeApps.url}`}
            title={'Business Intelligence Universa'}
            style={{
              width: '100%',
              height: '100vh',
            }}
            height='100%'
            width='100vh'
            loading={'lazy'}
            frameBorder={0}
          />
        </div>
      ) : null}
    </>
  )
}

export default AppsPage
